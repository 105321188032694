@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300;500;700&display=swap);

:root {
    --main: #222831;
    --main-darken: #1a1f25;
    --secondary : #289672;
    --light : #ffffff;
    --third : #fbd46d;
    --pading : 10px
}

*{
    font-family: 'Poppins', sans-serif;
}

.jamms-font-second{
    font-family: 'Bebas Neue', cursive;
}

.jamms-font-50{
    font-size: 50px;
}

.jamms-font-70{
    font-size: 70px;
}

.jamms-font-100{
    font-size: 100px;
}
.jamms-font-40{
    font-size: 40px;
}

.jamms-font-30{
    font-size: 30px;
}

.jamms-font-20{
    font-size: 20px;
}
.jamms-font-14{
    font-size: 14px;
}

.jamms-font-10{
    font-size: 10px;
}

.jamms-font-12{
    font-size: 12px;
}

.jamms-main{
    color: #222831;
    color: var(--main);
}

.jamms-third{
    color: #fbd46d;
    color: var(--third);
}

.jamms-secondary{
    color: #289672;
    color: var(--secondary);
}

.jamms-light {
    color: #ffffff;
    color: var(--light);
}




.jamms-bg-main{
    background-color: #222831;
    background-color: var(--main);
}


.jamms-bg-third{
    background-color: #fbd46d;
    background-color: var(--third);
}

.jamms-bg-secondary{
    background-color: #289672;
    background-color: var(--secondary);
}


.jamms-bg-light {
    background-color: #ffffff;
    background-color: var(--light);
}



.jamms-link{
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}

.jamms-link:hover{
    text-decoration: none;
    color: inherit;
}






.jamms-button{
    background-color: #222831;
    background-color: var(--main);
    color: #ffffff;
    color: var(--light);
    padding: 10px 30px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.jamms-button:focus{
    background-color:#1a1f25;
    background-color:var(--main-darken);
    opacity: 0.9;
    outline: none;

}

.jamms-button:hover{
    background-color: #1a1f25;
    background-color: var(--main-darken);
}

.jamms-button-secondary{
    background-color: #289672;
    background-color: var(--secondary);
    color: #ffffff;
    color: var(--light);
    padding: 10px 30px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.jamms-button-secondary:focus{
    opacity: 0.9;
    outline: none;
}

.jamms-button-light{
    background-color: #ffffff;
    background-color: var(--light);
    color: #222831;
    color: var(--main);
    padding: 10px 30px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.jamms-button-light:focus{
    opacity: 0.9;
    outline: none;

}


 

@media (max-width: 768px) {
    h1
    {
        font-size: 40px !important;
    }
    p{
        font-size: 14px;
    }
}
   

.jamms-shadow{
    box-shadow: -1px 3px 6px 0 rgba(0,0,0,.16);
}



.jamms-clickable-el{
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.jamms-clickable-el:hover{
    opacity: 0.7;
}

.jamms-clickable-el:focus{
    opacity: 0.9;
}

.jamms-active-state{
    font-weight: bolder;
    color: brown;
}


.jamms-fixed{
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 1;
}

:root {
    --main: #222831;
    --main-darken: #1a1f25;
    --secondary : #289672;
    --light : #ffffff;
    --third : #fbd46d;
    --pading : 10px
}

.jamms-jumbotron{
    /* background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./../Images/jumbotron.jpg'); */
    /* background-image: linear-gradient( 65.5deg,  #222831 -15.1%, #4f8a8b 71.5% ); */
    /* background-image: linear-gradient( 109.6deg, #222831 11.2%, #4f8a8b 91.1% ); */
    height: 700px;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    /* min-height:100%; */
    background:url(/static/media/gallery_2.98d72e5d.jpeg) rgba(12, 41, 37, 0.748);
    background-size:cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}


.jamms-package-sablon-container{
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(226,240,254,1) 0%, rgba(255,247,228,1) 90% );
}


.btn-secondary:focus{
    background-color: #289672;
    background-color: var(--secondary);
}

.jamms-banner-subscription{
    background-image: linear-gradient(180deg,#289672,#222831);
    border-radius: 25px;
}


.row-ig {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column-ig {
    flex: 25% 1;
    max-width: 25%;
    padding: 0 5px;
  }
  
  .column-ig .img-class {
    margin-top: 10px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column-ig {
      flex: 50% 1;
      max-width: 50%;
    }
  }
  

