
:root {
    --main: #222831;
    --main-darken: #1a1f25;
    --secondary : #289672;
    --light : #ffffff;
    --third : #fbd46d;
    --pading : 10px
}

.jamms-jumbotron{
    /* background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./../Images/jumbotron.jpg'); */
    /* background-image: linear-gradient( 65.5deg,  #222831 -15.1%, #4f8a8b 71.5% ); */
    /* background-image: linear-gradient( 109.6deg, #222831 11.2%, #4f8a8b 91.1% ); */
    height: 700px;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    /* min-height:100%; */
    background:url('./../Images/gallery_2.jpeg') rgba(12, 41, 37, 0.748);
    background-size:cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}


.jamms-package-sablon-container{
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(226,240,254,1) 0%, rgba(255,247,228,1) 90% );
}


.btn-secondary:focus{
    background-color: var(--secondary);
}

.jamms-banner-subscription{
    background-image: linear-gradient(180deg,#289672,#222831);
    border-radius: 25px;
}


.row-ig {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column-ig {
    flex: 25%;
    max-width: 25%;
    padding: 0 5px;
  }
  
  .column-ig .img-class {
    margin-top: 10px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column-ig {
      flex: 50%;
      max-width: 50%;
    }
  }
  
