
:root {
    --main: #222831;
    --main-darken: #1a1f25;
    --secondary : #289672;
    --light : #ffffff;
    --third : #fbd46d;
    --pading : 10px
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300;500;700&display=swap');

*{
    font-family: 'Poppins', sans-serif;
}

.jamms-font-second{
    font-family: 'Bebas Neue', cursive;
}

.jamms-font-50{
    font-size: 50px;
}

.jamms-font-70{
    font-size: 70px;
}

.jamms-font-100{
    font-size: 100px;
}
.jamms-font-40{
    font-size: 40px;
}

.jamms-font-30{
    font-size: 30px;
}

.jamms-font-20{
    font-size: 20px;
}
.jamms-font-14{
    font-size: 14px;
}

.jamms-font-10{
    font-size: 10px;
}

.jamms-font-12{
    font-size: 12px;
}

.jamms-main{
    color: var(--main);
}

.jamms-third{
    color: var(--third);
}

.jamms-secondary{
    color: var(--secondary);
}

.jamms-light {
    color: var(--light);
}




.jamms-bg-main{
    background-color: var(--main);
}


.jamms-bg-third{
    background-color: var(--third);
}

.jamms-bg-secondary{
    background-color: var(--secondary);
}


.jamms-bg-light {
    background-color: var(--light);
}



.jamms-link{
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}

.jamms-link:hover{
    text-decoration: none;
    color: inherit;
}






.jamms-button{
    background-color: var(--main);
    color: var(--light);
    padding: 10px 30px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.jamms-button:focus{
    background-color:var(--main-darken);
    opacity: 0.9;
    outline: none;

}

.jamms-button:hover{
    background-color: var(--main-darken);
}

.jamms-button-secondary{
    background-color: var(--secondary);
    color: var(--light);
    padding: 10px 30px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.jamms-button-secondary:focus{
    opacity: 0.9;
    outline: none;
}

.jamms-button-light{
    background-color: var(--light);
    color: var(--main);
    padding: 10px 30px;
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.jamms-button-light:focus{
    opacity: 0.9;
    outline: none;

}


 

@media (max-width: 768px) {
    h1
    {
        font-size: 40px !important;
    }
    p{
        font-size: 14px;
    }
}
   

.jamms-shadow{
    box-shadow: -1px 3px 6px 0 rgba(0,0,0,.16);
}

